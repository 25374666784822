/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect} from 'react'
import {getRequestParams} from '../../../../_metronic/helpers'
import {useLocation} from 'react-router'
import {useParams} from 'react-router-dom'
import api from '../../../../requests'
import InfiniteScroll from 'react-infinite-scroll-component';
import moment from 'moment'
import {Header} from './Header'

const ActivityListing: React.FC = () => {
  const listingCount = 25
  let pageSize = listingCount;
  let offset = 0;

  const [moreRecords, setMoreRecords] = useState(true)
  const [totalRecords, setTotalRecords] = useState(0);
  const [allRecords, setAllRecords] = useState([]);
  const [tenantRecord, setTenantRecord] = useState([]);


  const {id} = useParams()
  const getListing = (scroll = true, limit = 0) => {
    offset = 0;

    if (scroll == true) {
      offset = allRecords.length
      pageSize = listingCount;
    } else if (limit == 0) {
      pageSize = totalRecords;
    } else {
      pageSize = limit;
    }
    const params = { ...getRequestParams('', offset, pageSize) };
    api.requests('tenants/activities').fetchById(id, params).then((res) => {
      const {tenant_record, activities}  = res.data;
      setTenantRecord(tenant_record)
      let total: any = allRecords.concat(activities);
      if (activities.length == 0 || total.length < listingCount) {
        setMoreRecords(false);
      } else {
        setMoreRecords(true);
      }
      if (scroll == true) {
        setAllRecords(total);
        setTotalRecords(total.length)
      } else {
        setAllRecords(activities);
      }
    }).catch((err) => {
    });


  }
  useEffect(getListing, [id])
  return (
    <>
    <Header record={tenantRecord} />
    <div className='card mb-5 mb-xl-10'>
    <div className='card-header border-0 pt-5'></div>
    <div className='card-body pt-0 dataTables_wrapper dt-bootstrap4 no-footer'>
      <div className='table-responsive'>
        {/* START Infinite scroll here */}
        <InfiniteScroll
            dataLength={allRecords.length}
            next={getListing}
            hasMore={moreRecords}
            loader={<h4>Loading...</h4>}
          >
          <table className='table align-middle table-row-dashed fs-6 gy-2 dataTable no-footer'>
            <thead>
              <tr className='text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0'>
                <th>IP</th>
                <th>BROWSER</th>
                <th>LOGIN TIME</th>
              </tr>
            </thead>
            <tbody>
              {allRecords.map((record, i) => {
                return (
                  <tr key={i}>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                          <span className='text-dark fw-bolder text-hover-primary mb-1 fs-6'>
                            {record.ip}
                          </span>
                        </div>
                      </div>
                    </td>
                    <td>{record.browser_info}</td>
                    <td>{moment(record.login_time).format('YYYY-MM-DD HH:mm:ss')}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </InfiniteScroll>
        {/* END Infinite scroll here */}
      </div>
    </div>
  </div>
  </>
  )
}

export {ActivityListing}
