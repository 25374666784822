import React, {Suspense, lazy} from 'react'
import {Route, Switch} from 'react-router-dom'
import {FallbackView} from '../../../_metronic/partials'
import {ActivityListing} from './view/ActivityListing'
import {CollectionListing} from './view/CollectionListing'

// const CollectionFormsView = lazy(() => import('./custom_forms/Listing'))

function ViewTabsWrapper() {
  return (
    <>
        <Suspense fallback={<FallbackView />}>
          <Switch>
            <Route path='/tenant/:id/activity' component={ActivityListing} />
            <Route path='/tenant/:id/collections' component={CollectionListing} />
          </Switch>
        </Suspense>
    </>
  )
}

export default ViewTabsWrapper
