import React, {FC} from 'react'
import {KTSVG} from '../../../_metronic/helpers'
import Switch from 'react-switch'

const TableRows: FC = (props:any) => {
    const {record} = props;
    const {first_name, last_name, email, phone, address, city, state, country, pincode, verified} =  record;

    const handleVerified = (nextChecked:any) => {
console.log(nextChecked);
        // let val = nextChecked == true ? activeInnerTab : '';
        
        // handleSortingActions(event)
      };
    
    return (

       <tr>
            <td>
              {first_name +' '+  last_name}
            </td>
            <td>
              {email}
            </td>
            <td>
                {phone}
            </td>
            <td>
            {country && (
            <>
            <a
              href={
                'https://maps.google.com/maps?q=' +
                address +
                ' ' +
                city +
                ', ' +
                state +
                ', ' +
                country +
                ', ' +
                pincode
              }
              rel="noreferrer"
              target='_blank'
              className='text-dark fw-bolder text-hover-primary d-block fs-6'
            >
              {address}
            </a>
              <span className='text-muted fw-bold text-muted d-block fs-7'>
                {city +', '+ state +', '+ country +', '+ pincode}
              </span>
              </>
            )}
            </td>
            <td>
                <Switch 
                    onChange={handleVerified}
                    checked={verified === true}
                    className="react-switch"
                />
            </td>
            <td>
              <div className='d-flex justify-content-end flex-shrink-0'>
                
                <a
                  href='#'
                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                >
                  <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                </a>
                <a
                  href='#'
                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                >
                <KTSVG path='/media/icons/duotune/arrows/arr064.svg' className='svg-icon-2' />
                </a>
              </div>
            </td>
          </tr>
    )
  }
  
  export {TableRows}
  