/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { StoreContext } from "../../../../store";
import { useSelector } from 'react-redux'
import Dropdown from 'react-bootstrap/Dropdown';
import clsx from 'clsx'

import { toAbsoluteUrl } from '../../../helpers'
const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px'


const HeaderUserMenu = () => {
  const profile_info = useSelector((state) => state.profile_info)
  const [logoUrl, setLogoUrl] = useState(toAbsoluteUrl('/media/avatars/150-2.jpg'))

  const { resetAuth } = useContext(StoreContext);
  const logoutUser = () => {
    resetAuth();
    localStorage.clear();
  };
  

const setData = () => {
  setLogoUrl(toAbsoluteUrl('/media/avatars/150-2.jpg'))
  // if(profile_info && profile_info.image){
  //   convertImageToBase64(profile_info.image, function (dataUrl:any) {
  //     setLogoUrl(dataUrl)
  //   })
  // }
  
}
useEffect(setData,[profile_info])

  return (
 

    <Dropdown className={clsx('d-flex align-items-center tree-dots-dropdown', toolbarButtonMarginClass)}>
      <Dropdown.Toggle variant="" >
        <div className={clsx('cursor-pointer symbol', toolbarUserAvatarHeightClass)}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
        >
          <img src={logoUrl} alt='metronic' />
        </div>
      </Dropdown.Toggle>

      <Dropdown.Menu className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'>
        <Dropdown.Item>
          <div className='menu-item px-3'>
            <div className='menu-content d-flex align-items-center px-3'>
              <div className='symbol symbol-50px me-5'>
                <img alt='Logo' src={logoUrl} />
              </div>

              <div className='d-flex flex-column'>
                <div className='fw-bolder d-flex align-items-center fs-5'>
                  {profile_info && profile_info.name ? profile_info.name : ''}
                </div>
                <a href='#' className='fw-bold text-muted text-hover-primary fs-7'>
                  {profile_info && profile_info.email ? profile_info.email : ''}
                </a>
              </div>
            </div>
          </div>
          <div className='separator my-2'></div>
        </Dropdown.Item>
        <Dropdown.Item>
          <div className='menu-item px-5'>
            <Link to={'/crafted/pages/profile'} className='menu-link px-5'>
              My Profile
            </Link>
          </div>
        </Dropdown.Item>
        <Dropdown.Item>
          <div className='menu-item px-5'>
            <Link onClick={logoutUser} className='menu-link px-5' to="#">
              Sign Out
            </Link>
          </div>
        </Dropdown.Item>
      </Dropdown.Menu>

    </Dropdown>

  )
}

export { HeaderUserMenu }
