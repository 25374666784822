import React, {FC} from 'react'
import {KTSVG, DeleteButton} from '../../../_metronic/helpers'
import {Link} from 'react-router-dom'

const TableRows: FC = (props:any) => {
    const {record, updateListing} = props;
    const {_id, name, created_by, date_created} =  record;
    return (

       <tr>
            <td>
              <Link to={'/tenant/'+_id+'/activity'}>
              {name}
              </Link>
            </td>
            <td>
              {created_by ? created_by.name : '-'}
            </td>
            <td>
              {created_by ? created_by.email : '-'}
            </td>
            <td>
              {date_created}
            </td>
            <td>
              <div className='d-flex justify-content-end flex-shrink-0'>
                <DeleteButton id={_id} url={'tenants'} buttonClass={'btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'} textName={<KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-2' />} updateListing={updateListing} />
              </div>
            </td>
          </tr>
    )
  }
  
  export {TableRows}
  