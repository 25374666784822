import axios from "axios";

export default {

    requests(baseUrl) {
      
      const url = process.env.REACT_APP_API_URL  + '/' +  baseUrl;
        return {
            fetchAll: (params) => axios.get(url, {params}),
            fetchById: (id, params) => axios.get(url +'/'+ id, {params}),
            fetchByParms: (id, params) => axios.post(url +'/'+ id, {params}),
            create: newRecord => axios.post(url, newRecord),
            update: (id, updateRecord) => axios.put(url +'/'+  id, updateRecord),
            delete: (id, params) => axios.delete(url +'/'+  id, {params}),
        }
    }
}
