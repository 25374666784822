import React from 'react'
import ReactDOM from 'react-dom'
// Redux
// https://github.com/rt2zz/redux-persist
import {Provider} from 'react-redux'
import {Chart, registerables} from 'chart.js'

// Apps
import {App} from './app/App'
import {StoreProvider} from './store'
import storeData from './reduxStore'
import {ToastProvider} from 'react-toast-notifications'
import './app/modules/Common.css'
import axios from 'axios'

/**
 * TIP: Replace this style import with dark styles to enable dark mode
 *
 * import './_metronic/assets/sass/style.dark.scss'
 *
 * TIP: Replace this style import with rtl styles to enable rtl mode
 *
 * import './_metronic/assets/css/style.rtl.css'
 **/
import './_metronic/assets/sass/style.scss'
import './_metronic/assets/sass/style.react.scss'
/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const {PUBLIC_URL} = process.env
/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic Metronic mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */

/**
 * Inject Metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */

axios.interceptors.request.use(
  function (config: any) {
    const token = localStorage.getItem('auth_token');
    config.headers.token = token
      ? token.replace(/['"]+/g, '')
      : null
    return config
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error)
  }
)

Chart.register(...registerables)

ReactDOM.render(
  <StoreProvider>
    <Provider store={storeData}>
      <ToastProvider>
        <App basename={PUBLIC_URL} />
      </ToastProvider>
    </Provider>
  </StoreProvider>,
  document.getElementById('root')
)
