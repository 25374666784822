/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {KTSVG, getRequestParams} from '../../../_metronic/helpers'
import api from '../../../requests'
import InfiniteScroll from 'react-infinite-scroll-component';
import {TableHeaders} from '../components/index'
import {TableRows} from './TableRows'
type Props = {
  className: string
}

const TableHeading = ['Tenant Name', 'Name', 'Email', 'Created Date']

const TenantListing: React.FC<Props> = ({className}) => {

  const listingCount = 25
  let pageSize = listingCount;
  let offset = 0;

  const [moreRecords, setMoreRecords] = useState(true)
  const [totalRecords, setTotalRecords] = useState(0);
  const [allRecords, setAllRecords] = useState([]);
  const [searchTitle, setSearchTitle] = useState("");

  const getListing = (scroll = true, limit = 0, search= '') => {
    if (search == "") {
      search = searchTitle;
    }
    offset = 0;

    if (scroll == true) {
      offset = allRecords.length
      pageSize = listingCount;
    } else if (limit == 0) {
      pageSize = totalRecords;
    } else {
      pageSize = limit;
    }
    const params = { ...getRequestParams(search, offset, pageSize) };
    api.requests('tenants').fetchAll(params).then((res) => {
      const {records}  = res.data;
      let total: any = allRecords.concat(records);
      if (records.length == 0 || total.length < listingCount) {
        setMoreRecords(false);
      } else {
        setMoreRecords(true);
      }
      if (scroll == true) {
        setAllRecords(total);
        setTotalRecords(total.length)
      } else {
        setAllRecords(records);
      }

    })
      .catch((err) => {
        if (err && err.response && err.response.data && typeof err.response.data === "string") {
        //   addToast(err.response.data,
        //     {
        //       appearance: "error",
        //       autoDismiss: true,
        //     }
        //   );
        // } else {
        //   addToast("Unknown error occurred",
        //     {
        //       appearance: "error",
        //       autoDismiss: true,
        //     }
        //   );
        }
      });


  }
  const updateListing = () => {
    getListing(false, listingCount)
  }
  useEffect(getListing, [])

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>Tenants</span>
        </h3>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <InfiniteScroll
            dataLength={allRecords.length}
            next={getListing}
            hasMore={moreRecords}
            loader={<h4>Loading...</h4>}
          >
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
              <TableHeaders headings={TableHeading} actions={true}/>
            {/* begin::Table */}
              {/* begin::Table head */}
              <tbody>
                {allRecords.map((row, i) => {
                    return <TableRows record={row} key={i} updateListing={updateListing}/>
                  })
                  }
                </tbody>
              {/* end::Table body */}
            </table>
          </InfiniteScroll>
            
            {/* end::Table head */}
            {/* begin::Table body */}
           
        
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
} 

export {TenantListing}