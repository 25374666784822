/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router'
import {useParams} from 'react-router-dom'
 
const Header: React.FC = (props:any) => {
  const location = useLocation()
  const {id} = useParams()
const {name, company_logo, company_name, created_by} = props.record;
console.log(props);
  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-body pt-9 pb-0'>
        <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
          {company_logo && (
            <div className='me-7 mb-4'>
              <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
                <img src={company_logo} alt='' />
              </div>
            </div>
          )}
        
          <div className='flex-grow-1'>
            <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
              <div className='d-flex flex-column'>
                <div className='d-flex align-items-center mb-2'>
                  {name}
                </div>

                <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                    <span className='me-3'>{company_name}</span>
                   {(created_by && created_by.email) && (
                    <>
                      <KTSVG
                      path='/media/icons/duotune/communication/com011.svg'
                      className='svg-icon-4 me-1'
                      />
                            {created_by.email}
                      </>
                   )}
                   
                
                </div>
              </div>

            </div>

          </div>
        </div>

        <div className='d-flex overflow-auto h-55px'>
          <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === '/tenant/'+id+'/activity' && 'active')
                }
                to={'/tenant/'+id+'/activity'}
              >
                Activities
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === '/tenant/'+id+'/collections' && 'active')
                }
                to={'/tenant/'+id+'/collections'}
              >
                Collections
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export {Header}
