const initState = {
  data: [],
  sidebar_data: [],
}
export default (state = initState, action) => {
  switch (action.type) {
    case 'UPDATE_TITLE': {
      return {
        ...state,
        data: action.data,
      }
    }
    case 'UPDATE_SIDEBAR': {
      return {
        ...state,
        sidebar_data: action.data,
      }
    }
    case 'UPDATE_DEFAULT_PAGE_URL': {
      return {
        ...state,
        default_url: action.data,
      }
    }
    case 'UPDATE_LOGO': {
      return {
        ...state,
        company_logo: action.data,
      }
    }
    case 'UPDATE_PROFILE_INFO': {
      return {
        ...state,
        profile_info: action.data,
      }
    }
    case 'UPDATE_DASHBOARD_COMPONENTS': {
      return {
        ...state,
        dashboard_components: action.data,
      }
    }
    case 'UPDATE_ROLE': {
      return {
        ...state,
        user_role_config: action.data ? action.data.config_access : false,
        user_role: action.data,
      }
    }
    default: {
      return state
    }
  }
}
