import { useRef, useEffect } from 'react'

export const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}
export const getRequestParams = (searchTitle, records, pageSize) => {
  let params = {}

  if (searchTitle) {
    params['title'] = searchTitle
  }

  if (records) {
    params['offset'] = records
  }

  if (pageSize) {
    params['limit'] = pageSize
  }

  return params
}

const arrayMoveMutate = (array, from, to) => {
  array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0])
}

export const arrayMove = (array, from, to) => {
  array = array.slice()
  arrayMoveMutate(array, from, to)
  return array
}

export const inArrayBykey = (needle, haystack, key) => {
  let length = haystack.length
  for (var i = 0; i < length; i++) {
    if (haystack[i][key] === needle) return haystack[i]
  }
  return false
}
export const inArray = (needle, haystack, key) => {
  let length = haystack.length
  for (var i = 0; i < length; i++) {
    if (haystack[i][key] === needle) return i
  }
  return false
}

export const serializeData = (data) => {
  let formData = new FormData()
  Object.keys(data).forEach(function (key) {
    formData.append(key, data[key])
  })
  return formData
}
export const checkImageURL = (url) => {
  return (url.match(/\.(jpeg|jpg|gif|png)$/) != null);
}

export const uploadConfigInfo = (files) => {
  return {
    endpoint: files.REACT_APP_SDK_S3_BUCKET_END_POINT, // Find your endpoint in the control panel, under Settings. Prepend "https://".
    forcePathStyle: false, // Configures to use subdomain/virtual calling format.
    region: files.REACT_APP_SDK_S3_REGION_NAME, // Must be "us-east-1" when creating new Spaces. Otherwise, use the region in your endpoint (e.g. nyc3).
    credentials: {
      accessKeyId: files.REACT_APP_SDK_S3_ACCESS_KEY_ID, // Access key pair. You can create access key pairs using the control panel or API.
      secretAccessKey: files.REACT_APP_SDK_S3_SECRET_ACCESS_KEY // Secret access key defined through an environment variable.
    }
  }
}

export const generateImageUrl = (image, files) => {
  let domain = (new URL(files.REACT_APP_SDK_S3_BUCKET_END_POINT));
  let subdomain = files.REACT_APP_SDK_S3_BUCKET_NAME
  const { protocol, host } = domain;
  return `${protocol}//${subdomain}.${host}/${image}`;
}

export const deleteUploadedImage = (url, config, S3FileUpload) => {
  const fileName = url.split('/').pop();
  S3FileUpload
    .deleteFile(fileName, config)
    .then((response) => {
      // console.log(response);
    })
}



export const permissionValidateByCollectionId = (rolePermissions, collectionId) => {
  let fullAccess = {
    add: true,
    edit: true,
    view: true,
    delete: true,
  }
  if (rolePermissions === undefined) {
    return {}
  }
  if (rolePermissions.full_access === true) {
    return fullAccess
  }
  const { permissions } = rolePermissions

  const pagePermissions = inArrayBykey(collectionId, permissions, 'collection_id')
  fullAccess.add = pagePermissions.add ? pagePermissions.add : false
  fullAccess.edit = pagePermissions.edit ? pagePermissions.edit : false
  fullAccess.delete = pagePermissions.delete ? pagePermissions.delete : false
  fullAccess.view = pagePermissions.view ? pagePermissions.view : false

  return fullAccess
}

export function useHorizontalScroll() {
  const elRef = useRef()
  useEffect(() => {
    const el = elRef.current

    if (el) {
      const onWheel = (e) => {
        if (e.shiftKey === false) return
        if (e.deltaY === 0) return
        e.preventDefault()
        el.scrollTo({
          left: el.scrollLeft + e.deltaY,
          // behavior: "smooth"
        })
      }
      el.addEventListener('wheel', onWheel)
      return () => el.removeEventListener('wheel', onWheel)
    }
  }, [])
  return elRef
}
export function arrayBufferToBase64(buffer) {
  var binary = ''
  var bytes = [].slice.call(new Uint8Array(buffer))
  bytes.forEach((b) => (binary += String.fromCharCode(b)))
  return window.btoa(binary)
}
export function viewImage(image) {
  var base64Flag = 'data:image/jpeg;base64,'
  var imageStr = ''
  if (image['data']) {
    imageStr = arrayBufferToBase64(image.data)
  } else {
    imageStr = image
  }
  return base64Flag + imageStr
}
export function getMine(b64) {
  const signatures = {
    JVBERi0: 'application/pdf',
    R0lGODdh: 'image/gif',
    R0lGODlh: 'image/gif',
    iVBORw0KGgo: 'image/png',
    TU0AK: 'image/tiff',
    '/9j/': 'image/jpg',
    UEs: 'application/zip',
    PK: 'application/zip',
    PHN2ZyB: 'image/svg+xml',
    W3sKICAi: 'text/plain',
  }
  for (var s in signatures) {
    if (b64.indexOf(s) === 0) {
      return signatures[s]
    }
  }
}
export function getExtension(b64) {
  const signatures = {
    JVBERi0: 'pdf',
    R0lGODdh: 'gif',
    R0lGODlh: 'gif',
    iVBORw0KGgo: 'png',
    TU0AK: 'tiff',
    '/9j/': 'jpg',
    UEs: 'zip',
    PK: 'zip',
    PHN2ZyB: 'svg',
    W3sKICAi: 'text',
  }
  for (var s in signatures) {
    if (b64.indexOf(s) === 0) {
      return signatures[s]
    }
  }
}
export function fileExtension(path) {
  return getExtension(path)
}
export function decodeFile(path) {
  return 'data:' + getMine(path) + ';base64,' + path
}

export function sortingValueByFieldNames(sortingArray) {
  return sortingArray.sort((a, b) => (a.field_name > b.field_name ? 1 : -1))
}
export function mentionCCArray(allRecords) {
  return allRecords.filter(allRecord => allRecord.data_type === 'email' || allRecord.data_type === 'user' || allRecord.data_type === 'created_by' || allRecord.data_type === 'modified_by' || allRecord.data_type === 'owner').map(function (val) {
    return { display: '@' + val.field_name, id: val.api_name }
  })
}
export function mentionEditorArray(allRecords) {
  return allRecords.map(allRecord => {
    return { text: allRecord.field_name + '([' + allRecord.api_name + '])', value: '[' + allRecord.api_name + ']', url: window.location.origin + '/collection/update_tags' }
  })
}
export function mentionSubjectArray(allRecords) {
  return allRecords.map(allRecord => {
    return { display: '@' + allRecord.field_name, id: allRecord.api_name }
  })
}
export function assignSelectBoxOptions(record, selectVal, selectName) {
  let defaultOptions = [{ value: '', label: 'Select..' }]
  Object.keys(record).forEach(function (key) {
    defaultOptions.push({ value: record[key][selectVal], label: record[key][selectName] });
  });
  return defaultOptions;
}

export function filteredFieldName(allRecords) {
  const filterListing = allRecords.filter(allRecord => {
    return allRecord.data_type === 'date' ||
      allRecord.data_type === 'name' ||
      allRecord.data_type === 'text' ||
      allRecord.data_type === 'number' ||
      allRecord.data_type === 'checkbox' ||
      allRecord.data_type === 'lookup' ||
      allRecord.data_type === 'radio' ||
      allRecord.data_type === 'address' ||
      allRecord.data_type === 'select_list' ||
      allRecord.data_type === 'pills' ||
      allRecord.data_type === 'phone' ||
      allRecord.data_type === 'email' ||
      allRecord.data_type === 'user' ||
      allRecord.data_type === 'created_date' ||
      allRecord.data_type === 'modified_date' ||
      allRecord.data_type === 'created_by' ||
      allRecord.data_type === 'modified_by' ||
      allRecord.data_type === 'currency'
  })
  return filterListing;
}

export function updateSideBarNav(sidebarData, dispatch, data, status, key) {

  switch(status){
    case 'update':
     const updatedData =  sidebarData.sidebarData.map((item) => {
        if(item._id === data._id){
          console.log(item);
          if(item.key !== 'page'){
            return  { ...item, ...{tabs: data.tabs,name: data.name, key: key}}
          }else if(item.key === 'page'){
            return  { ...item, ...{icon: data.icon,name: data.name, key: key}}
          }
        }else{
          return item
        }
      })
      sidebarData.sidebarData = updatedData
      dispatch({ type: 'UPDATE_SIDEBAR', data: {...sidebarData} })
    break
    case 'add':
      sidebarData.sidebarData = [...sidebarData.sidebarData, data]
      dispatch({ type: 'UPDATE_SIDEBAR', data: {...sidebarData} })
    break
    case 'delete':
        const newData = sidebarData.sidebarData.filter((item) => item._id !== data)
        sidebarData.sidebarData = [...newData]
        dispatch({ type: 'UPDATE_SIDEBAR', data: {...sidebarData} })
    break
    case 'application_update':
        sidebarData.sidebarData = [...data]
        dispatch({ type: 'UPDATE_SIDEBAR', data: {...sidebarData} })
    break
  }
 
}

export const updateDefaultPageUrl = (defaultApplicationListing, dispatch) => {
  let url = '/collections'
  if(defaultApplicationListing){
    if(defaultApplicationListing.key === 'page' && defaultApplicationListing.page_id){
      url = '/dashboard/'+defaultApplicationListing.page_id
    }else if(defaultApplicationListing.key === 'collection' && defaultApplicationListing.collection_id){
      url = '/application/collections/'+defaultApplicationListing.collection_id
    }
  }
  dispatch({ type: 'UPDATE_DEFAULT_PAGE_URL', data:  url})
  return url
}

export const replaceItem = (updater, index, item) => {
  updater(array => array.map((value, i) => i === index ? item : value));
};


export const convertImageToBase64 = (imgUrl, callback) => {
  let xhRequest = new XMLHttpRequest();
  xhRequest.onload = function () {
    let reader = new FileReader();
    reader.onloadend = function () {
      callback(reader.result);
    }
    reader.readAsDataURL(xhRequest.response);
  };
  xhRequest.open('GET', imgUrl);
  xhRequest.responseType = 'blob';
  xhRequest.send();
}
