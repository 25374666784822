import swal from 'sweetalert';
import api from '../../../requests'
const DeleteButton = (props) => {
const {id, url, updateListing, extraParm, textName, buttonClass, customMessage}  = props;

const deleteRecordAlert = async () => {
    
    await swal({
        title: "Are you sure?",
        text: customMessage ? customMessage : "Are you sure that you want to delete this record?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
    }).then((willDelete) => {
        if (willDelete) {
            api.requests(url).delete(id, {...extraParm}).then((res) => {
                swal("Deleted!", "Record deleted successfully!", "success");
                updateListing();
                closeDeleteAlertBox();
            }).catch((err) => {
                swal("Error! Something went wrong. Please try again.", {
                icon: "error",
                });
                closeDeleteAlertBox();
            });
        }
    });
}
const closeDeleteAlertBox = () => {
    setTimeout(() => {
        swal.close();
    }, 3000)
}


    return (
        <>
            <button className={buttonClass ? buttonClass : 'dropdown-item'}  onClick={deleteRecordAlert}>{textName ? textName : 'Delete'}</button>
        </>
    )
  }
  
  export {DeleteButton}
  