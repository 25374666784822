/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useContext } from 'react'
import { useFormik } from 'formik'
import { Link } from 'react-router-dom'
import { StoreContext } from "../../../../store";
import '../../auth/Auth.css'
import clsx from 'clsx'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import * as Yup from 'yup'
import { login } from '../redux/AuthCRUD'

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/
const linkedInAuthUrl = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${process.env.REACT_APP_LINKEDIN_CLIENT_ID}&scope=r_emailaddress&r_liteprofile&state=123456&redirect_uri=${process.env.REACT_APP_LINKEDIN_CALLBACK_URL}`
const googleLoginUrl = `https://accounts.google.com/o/oauth2/v2/auth?scope=https://www.googleapis.com/auth/userinfo.email&access_type=offline&response_type=code&redirect_uri=${process.env.REACT_APP_GOOGLE_CALLBACK_URL}&client_id=${process.env.REACT_APP_GOOGLE_CLIENT_ID}`;

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(4, 'Minimum 4 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const initialValues = {
  email: '',
  password: '',
}


export function Login() {

  const {
    authenticateUser,
    setUserData,
  } = useContext(StoreContext);
  const [loading, setLoading] = useState(false)
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      setTimeout(() => {
        login(values.email, values.password)
          .then((res) => {
            setLoading(false)
            const { email, _id, first_name, last_name, on_boarding_form, tenant_id, token, tenant_name, tenant_date, created_date, company_logo, external_user, avatar, application_view } = res.data;
            setUserData({ email, first_name, 'last_name': last_name, 'on_boarding_form': on_boarding_form, 'id': _id, '_id': _id, 'tenant_id': tenant_id, 'tenant_name': tenant_name, 'tenant_date': tenant_date, 'company_logo': company_logo ? company_logo : '', 'created_date': created_date, 'external_user': external_user ? external_user : false, avatar:avatar ? avatar : '', application_view:application_view });
           
            res.data['company_logo'] = company_logo ? company_logo : '';
            localStorage.setItem('user', JSON.stringify(res.data));
            authenticateUser(token);
          })
          .catch((err) => {
            setLoading(false)
            setSubmitting(false)
            if (err && err.response && err.response.data && typeof err.response.data === "string") {
              setStatus(err.response.data)
            } else {
              setStatus("Unknown error occurred. Please try again later")
            }
          })
      }, 1000)
    },
  })
  return (
    <>
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        onSubmit={formik.handleSubmit}
        id='kt_login_signin_form'
      >
        <div className="text-center mb-11">
          <a href="#" className="mb-0 mb-lg-12">
            <img alt="Logo" src={toAbsoluteUrl('/media/logos/logo.png')} className="h-60px h-lg-40px" />
          </a>
        </div>
        <div className="text-center mb-11">
          <h1 className="text-dark fw-bolder mb-3">Sign in</h1>
          <div className="text-gray-500 fw-semibold fs-6"></div>
        </div>
        {formik.status && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>{formik.status}</div>
          </div>
        )}

        <div className="fv-row mb-8 fv-plugins-icon-container">
          <input
            placeholder='Email'
            {...formik.getFieldProps('email')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              { 'is-invalid': formik.touched.email && formik.errors.email },
              {
                'is-valid': formik.touched.email && !formik.errors.email,
              }
            )}
            type='email'
            name='email'
            autoComplete='off'
          />
          {formik.touched.email && formik.errors.email && (
            <div className='fv-plugins-message-container'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          )}
        </div>
        <div className="fv-row mb-3 fv-plugins-icon-container">
          <input
            type='password'
            autoComplete='off'
            {...formik.getFieldProps('password')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {
                'is-invalid': formik.touched.password && formik.errors.password,
              },
              {
                'is-valid': formik.touched.password && !formik.errors.password,
              }
            )}
          />
          {formik.touched.password && formik.errors.password && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.password}</span>
              </div>
            </div>
          )}
        </div>
        
        <div className="d-grid mb-10">
          <button type="submit" id="kt_sign_in_submit" className="btn btn-primary">

            {!loading && <span className='indicator-label'>Sign In</span>}
            {loading && (
              <span className='indicator-progress' style={{ display: 'block' }}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
    </>
  )
}
