import React, {FC} from 'react'

const TableHeaders: FC = (props:any) => {
  const {headings, actions} = props;
    return (
        <thead>
        <tr className='fw-bolder text-muted'>
          {headings.map((element:any, i:any) => {
            return <th className='min-w-150px' key={i}>{element}</th>
            })
          }
         {actions && (
           <th className='min-w-100px text-end'>Actions</th>
         )}
        </tr>
      </thead>
    )
  }
  
  export {TableHeaders}
  